// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@nathanvda/cocoon")

import '../styles/application'
import 'select2/dist/js/select2.full.js'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons';
import 'app'
import 'flatpickr'
import 'print'
import 'dashboard'
import 'product_search'
import 'customers'
import 'purchase_invoices'
import 'sales'
import 'payments'
import 'aluminia'
import 'aluminium_prices'
import 'accessories'
import 'finances'
import 'notifications'
import 'reports'
import 'modal_link'
import 'form_input_nav'
import 'ajax_handler'
import 'inline_editable'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
