import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  $('#payment_items_container').on('keyup', '.input-payment-payable', function() {
    const $this = $(this);
    const prev_value = $(this).data('prevValue') || '';
    if(this.value == prev_value) return;

    $this.closest('tr').find('.input-payment-payable-id:not(:checked)').click();

    $this.closest('#payment_items_container').trigger('calculate_total');
    $(this).data('prevValue', this.value);
  }).on('change', '.input-payment-payable', function(){
    $(this).closest('#payment_items_container').trigger('calculate_total_unpaid');
  }).each(function(){
    if(!$(this).data('prevValue')) $(this).data('prevValue', this.value);
  });
  $('.input-payment-payable-id').on('click', function() {
    const $this = $(this);
    if($this.prop('checked')) {
      $this.closest('tr').addClass('table-active');
    } else {
      $this.closest('tr').removeClass('table-active');
    }
    $this.closest('table').trigger('calculate_total');
  }).each(function(){$(this).triggerHandler('click')});
  $('#payment_items_container tbody td.selectable').click(function(e) {
    $(this).closest('tr').find('.input-payment-payable-id').click();
  }).css('cursor', 'pointer');

  $('#payment_items_container').on('calculate_total', function(){
    const $container = $(this);
    const $total = $container.data('totalSelector') ?
      $($container.data('totalSelector')) :
      $container.find('tfoot').removeClass('d-none').find('#payment_total_amount');
    var format = $total.data('moneyFormat');
    var total = Number($total.data('extraTotal') ? $total.data('extraTotal') : 0);

    $container.find('.table-active,.card,.balance-history').find('.input-payment-payable[id$=amount]').each(function() {
      const $input = $(this);
      total += $input.data('autoNumeric').getNumber();
    });

    if($total.is('input')) {
      $total.val($.money.format(total, format));
      if($total.data('max') && Number($total.data('max')) < total) {
        $total.closest('.form-group').addClass('field_with_errors');
      } else {
        $total.closest('.form-group').removeClass('field_with_errors');
      }
    } else {
      $total.text($.money.format(total, format));
    }
  }).trigger('calculate_total').on('calculate_total_unpaid', function(){
    const $container = $(this);
    const $totalPaid = $container.data('totalSelector') ?
      $($container.data('totalSelector')) :
      $container.find('tfoot').removeClass('d-none').find('#payment_total_amount');
    const $totalUnpaid = $($container.data('totalUnpaidSelector'))
    if(!$totalPaid.length || !$totalUnpaid.length) {
      return
    }
    let totalPaid = 0
    if($totalPaid.is('input')) {
      totalPaid = $.money.parse($totalPaid.val(), $totalPaid.data('moneyFormat'))
    } else {
      totalPaid = $.money.parse($totalPaid.text(), $totalPaid.data('moneyFormat'))
    }
    const totalUnpaid = Number($totalUnpaid.data('totalPrice')) - totalPaid
    const format = $totalUnpaid.data('moneyFormat')
    if($totalUnpaid.is('input')) {
      $totalUnpaid.val($.money.format(totalUnpaid, format));
      if(totalUnpaid < 0) {
        $totalUnpaid.closest('.form-group').addClass('field_with_errors');
      } else {
        $totalUnpaid.closest('.form-group').removeClass('field_with_errors');
      }
    } else {
      $totalUnpaid.text($.money.format(totalUnpaid, format));
    }
  }).trigger('calculate_total_unpaid')

  $('#payment_payables_container').on({
    'cocoon:after-insert': function(e, insertedItem) {
      setTimeout(function(){
        $('#payment_items_container').trigger('calculate_total');
        flatpickr($('.datepicker', insertedItem), {
          wrap: true,
          dateFormat: 'd-m-Y'
        });
      }, 0);
    },
    'cocoon:after-remove': function() {
      $('#payment_items_container').trigger('calculate_total');
    }
  });

  const $balanceHistoryAmount = $('[id^="payment_balance_histories_attributes_"][id$="_amount"]')
  if($balanceHistoryAmount.length) {
    $('#payment_payment_type_id').on('change', function() {
      const value = $(this).val()
      if(isNaN(value)) {
        const creditBalance = Number(value.split(':')[1])
        $balanceHistoryAmount.val(0).closest('tr').hide().closest('#payment_items_container').trigger('calculate_total')
        $('#payment_payment_on').closest('.form-group').hide()
      } else {
        $balanceHistoryAmount.closest('tr').show()
        $('#payment_payment_on').closest('.form-group').show()
      }
    }).trigger('change')
  }
});
