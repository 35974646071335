import $ from 'jquery'

$(document).on("turbolinks:render turbolinks:load", function() {
  const tempProductSelectTemplate = function(state) {
    const $div = $('<div class="product-search-select"><div class="name">' + state.text + '</div></div>');
    if(state.product_id) {
      $div.prepend('<i class="bi bi-box-seam pr-2"></i>');
    }
    return $div;
  };

  const tempProductResultTemplate = function(state) {
    const $span = $('<span class="p-1"><span>' + state.text + '</span></span>');
    if(state.product_id) {
      $span.prepend('<i class="bi bi-box-seam pr-2"></i>');
      if(state.color) {
        $span.append('<span class="badge badge-info ml-2">'+state.color+'</span>');
      }
      if(state.dimension) {
        $span.append('<span class="badge badge-info ml-2">'+state.dimension+'</span>');
      }
    }
    return $span;
  };

  $(':input[data-role="temp_product_search"]').select2({
    tags: true,
    closeOnSelect: true,
    maximumInputLength: 100,
    minimumInputLength: 2,
    minimumResultsForSearch: 0,
    templateSelection: tempProductSelectTemplate,
    templateResult: tempProductResultTemplate,
    containerCssClass: 'select2-focus-typing',
    dropdownCssClass: 'product-search-dropdown',
    containerCssClass: 'product-search-container select2-focus-typing',
    ajax: {
      delay: 800,
      url: function(){
        return $(this).data('url');
      },
      dataType: 'json',
      data: function(params) {
        let data = {
          q: params.term
        };
        return data;
      },
      error: function(xhr, status) {
        if(xhr.responseJSON && xhr.responseJSON.errors) {
          $.each(xhr.responseJSON.errors, function(i, error) {
            $.flash.create(error, 'danger', {id: 'error_product_search'});
          });
        } else if(status !== 'abort') {
          $.flash.create('Unknown error!', 'danger', {id: 'error_product_search'});
        }
      },
      beforeSend: function() {
        $.flash.remove('#error_product_search');
      }
    }
  })
});

$(document).on("turbolinks:load", function() {
  $('.input-purchase-invoice-po').on('keyup', function() {
    if(this.value == this.defaultValue) return;
    const $this = $(this);
    $this.closest('tr').find('.input-purchase-invoice-po-id:not(:checked)').click();
  });
  $('.input-purchase-invoice-po-id').on('click', function() {
    const $this = $(this);
    if($this.prop('checked')) {
      $this.closest('tr').addClass('table-active');
    } else {
      $this.closest('tr').removeClass('table-active');
    }
  }).each(function(){$(this).triggerHandler('click')});
  $('#product_type').on('change', function(){
    const $selected = $('option:selected', this);
    if($selected.data('shows')) {
      $($selected.data('shows')).show().find('[data-required]').each(function(){
        $(this).prop('required', $(this).data('required')).prop('disabled', false);
      });
    }
    if($selected.data('hides')) {
      $($selected.data('hides')).hide().find('[data-required]').each(function(){
        $(this).prop('required', false).prop('disabled', true);
      });
    }
  }).trigger('change');

  $(':input[data-role="temp_product_search"]').on('select2:select', function(e, isInit) {
    let data;
    if(e.params && e.params.data) {
      data = e.params.data
    }
    const isExisting = !!(data && data['product_id'])
    for (const [key, selector] of Object.entries($(this).data('selectors'))) {
      if(key === 'base_price') {
        selector = selector.replace('_existing', '')
        $(selector + '_existing')
          .val(data[key])
          .toggleClass('d-none', !isExisting)
          .prop('disabled', !isExisting)
          .filter('[data-required]').each(function(){
            $(this).prop('required', isExisting)
          })
        $(selector)
          .val(data[key])
          .toggleClass('d-none', isExisting)
          .prop('disabled', isExisting)
          .filter('[data-required]').each(function(){
            $(this).prop('required', !isExisting)
          })
      } else {
        $(selector).val(data[key])
        if(key !== 'product_id') {
          $(selector)
            .prop('disabled', isExisting)
            .filter('[data-required]').each(function(){
              $(this).prop('required', !isExisting)
            })
        }
      }
    }
  })
});
