import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  var escapeRegExp = function(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  var customerSelectTemplate = function(state) {
    var $span = $('<span class="d-flex flex-nowrap"><span class="flex-fill px-2">' + state.text + '</span></span>');
    if(!isNaN(state.id) && parseInt(state.id) > 0) {
      const data = $(state.element).data();
      $span.prepend('<i class="bi bi-person-fill"></i>');
      if(state.contactName || data.contactNames[0]) {
        $span.append('<span class="text-muted pr-2">'+(state.contactName || data.contactNames[0])+'</span>');
      }
    }
    return $span;
  };
  var $customer_select = $('#sale_customer_select');
  var $customerData = $('#customer_data');
  $customer_select.select2({
    tags: true,
    minimumResultsForSearch: 0,
    templateSelection: customerSelectTemplate,
    templateResult: customerSelectTemplate,
    containerCssClass: 'select2-focus-typing',
    allowClear: true,
    matcher: function(params, state) {
      if ($.trim(params.term) === '') {
        return state;
      }
      const data = $(state.element).data();
      const term = params.term.toLowerCase();
      let result = null;
      let modifiedState = $.extend({}, state, true);
      if (state.text && state.text.toLowerCase().indexOf(term) > -1) {
        modifiedState.text = state.text.replaceAll(new RegExp(escapeRegExp(term), 'gi'), '<strong><u>$&</u></strong>');
        result = modifiedState
      }
      $.each(data.contactNames, function(i, contactName){
        if (contactName.toLowerCase().indexOf(term) > -1) {
          modifiedState.contactName = contactName.replaceAll(new RegExp(escapeRegExp(term), 'gi'), '<strong><u>$&</u></strong>');
          result = modifiedState
          return false
        }
      })

      return result;
    }
  }).on('select2:select', function(e, isInit) {
    let $opt;
    if(e.params && e.params.data) {
      $opt = $(e.params.data.element);
    }
    if(!$opt || !$opt.length) $opt = $($(this).select2('data')[0].element);
    if($opt.data('allowCredit')) {
      $('#sale_is_payment_credit').prop('disabled', false);
      if(!isInit)
        $('#sale_is_payment_credit').val('true').trigger('change');
    } else {
      $('#sale_is_payment_credit').prop('disabled', true).val('false').trigger('change');
    }
    const customerId = $opt.data('select2Tag') ? '' : $opt.val();
    if($customerData.length && customerId !== String($customerData.data('customerId'))) {
      $customerData.data('customerId', { customer_id: customerId });
      $.get({
        url: $customerData.data('ajaxUrl'),
        data: { customer_id: customerId },
        error: function() {
          // fallback to update button
          $('#sale_update_button').click();
        },
        success: function(response) {
          if($.isPlainObject(response)) {
            $('#company_field').html(response.company_field).highlight();
            $('#delivery_address_field').html(response.delivery_address_field).highlight();
          } else {
            // fallback to update button
            $('#sale_update_button').click();
          }
        }
      });
    }
  }).trigger('select2:select', true);
  $('.discount-change-field').on('keyup', function(){
    const $row = $(this).closest('tr');
    const rawVal = $(this).val();
    const $productPrice = $('.product-price-field', $row);
    const $pricelist = $('.pricelist-field', $row);
    if(rawVal === $(this).data('originalValue')) {
      $productPrice.val($productPrice.data('originalValue')).prop('disabled', false);
      return;
    }
    $productPrice.prop('disabled', true);
    const discount = parseFloat(rawVal.replace('.', '').replace(',', '.'));
    if(isNaN(discount)) {
      $productPrice.val($pricelist.text());
      return;
    }
    const pricelist = $.money.parse($pricelist.text());
    const finalPrice = pricelist - (pricelist * discount / 100)
    $productPrice.val(finalPrice);
  });
  $('.btn-sale-print').click(function(){
    $.ajax({
      url: $(this).data('printedUrl'),
      method: 'POST',
      data: { '_method': $(this).data('printedUrlMethod') }
    })
  })
});
