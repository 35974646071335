import $ from 'jquery'
import flatpickr from "flatpickr/dist/flatpickr.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import weekSelectPlugin from "flatpickr/dist/plugins/weekSelect/weekSelect";
import { Indonesian } from "flatpickr/dist/l10n/id.js"
flatpickr.localize(Indonesian);

$(document).on("turbolinks:load", function() {
  flatpickr($('.datepicker-monthly'), {
    wrap: true,
    plugins: [
      new monthSelectPlugin({
        dateFormat: 'm-Y', //defaults to "F Y"
        altFormat: 'm-Y' //defaults to "F Y"
      })
    ]
  });
  flatpickr($('.datepicker-weekly'), {
    wrap: true,
    dateFormat: 'd-m-Y',
    weekNumbers: true,
    plugins: [
      new weekSelectPlugin({})
    ]
  });
  flatpickr($('.datepicker-range'), {
    wrap: true,
    mode: 'range',
    dateFormat: 'd-m-Y'
  });
  flatpickr($('.datepicker'), {
    wrap: true,
    dateFormat: 'd-m-Y'
  });
});
