import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  var generateModal = function() {
    const $modal = $('<div class="modal"></div>');
    let data = {};
    data.$dialog = $('<div class="modal-dialog"></div>').appendTo($modal);
    data.$content = $('<div class="modal-content"></div>').appendTo(data.$dialog);
    data.$header = $('<div class="modal-header"></div>').appendTo(data.$content);
    $('<button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>').appendTo(data.$header);
    data.$body = $('<div class="modal-body"></div>').appendTo(data.$content);
    data.$footer = $('<div class="modal-footer"></div>').appendTo(data.$content);
    $modal.data('modal', data);
    $modal.modal()
    return $modal;
  };
  $('.image-modal-link').click(function(e) {
    e.preventDefault();
    const imageUrl = $(this).attr('href');
    const title = $(this).find('img').attr('alt');
    const modal = $(this).data('modal') || {};
    var $modal = modal.$modal;
    if(!$modal) {
      $modal = generateModal();
      $modal.addClass('fade modal-product-image').appendTo('body');
    }

    const $header = $modal.data('modal').$header;
    $header.find('.modal-title').remove();
    if(title) {
      $('<h5 class="modal-title">'+title+'</h5>').prependTo($header);
    }

    const $img = $('<img src="'+imageUrl+'" alt="'+title+'" style="max-width: 100%;">');
    const $body = $modal.data('modal').$body;
    $body.html($img);

    $modal.modal('show');
    modal.$modal = $modal;
    $(this).data('modal', modal);
  });
  $('.ajax-modal-link').click(function(e) {
    e.preventDefault();
    const url = $(this).attr('href');
    const modal = $(this).data('modal') || {};
    const title = modal.title;
    var $modal = modal.$modal;
    if(!$modal) {
      $modal = generateModal();
      $modal.addClass('fade').appendTo('body');
    }

    const $header = $modal.data('modal').$header;
    $header.find('.modal-title').remove();
    if(title) {
      $('<h5 class="modal-title">'+title+'</h5>').prependTo($header);
    }

    const $body = $modal.data('modal').$body;
    $body.load(url, function() {
      $modal.modal('show');
    });
    $(this).data('modal', modal);
  });
});
