import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  var inputItemSelector = ':input:visible,.input-nav-item:visible';
  var triggerFocusF = function($input) {
    if($input.length) {
      if($input.hasClass('select2-hidden-accessible')) {
        $input.select2('focus');
        // if(!$.trim($input.val()).length)
        //   $input.select2('open');
      } else {
        $input.focus();
        if($input.is(':input')) $input.select();
      }
    }
  };
  var getContainerF = function($input) {
    const navData = $input.data('inputNav') || {};
    let $container = $();
    if(navData.parent) $container = $(navData.parent);
    if(!$container.length) $container = $input.closest('form');
    return $container;
  };
  var updateConnectionF = function($prev, $next) {
    const prevData = $prev.data('navData') || {};
    const nextData = $next.data('navData') || {};
    prevData.$next = $next;
    nextData.$prev = $prev;
    $prev.data('navData', prevData);
    $next.data('navData', nextData);
  }
  var updateTopBottomF = function($top, $bottom) {
    const topData = $top.data('navData') || {};
    const bottomData = $bottom.data('navData') || {};
    topData.$bottom = $bottom;
    bottomData.$top = $top;
    $top.data('navData', topData);
    $bottom.data('navData', bottomData);
  }
  var getNextF = function($input) {
    const navData = $input.data('inputNav') || {};
    let $next = $();
    if(navData.$next) $next = navData.$next;
    if(!$next.length) {
      const $container = getContainerF($input);
      if(navData.next) {
        if(!$container.length) $container = $(document);
        $next = $(navData.next, $container);
      } else if($container.length) {
        const $inputs = $container.find(inputItemSelector);
        const i = $inputs.index($input);
        if(i >= 0) {
          $next = $inputs.eq(i+1);
        }
      }
      if($next.length) {
        updateConnectionF($input, $next);
      }
    }
    return ($next.length && $next.is(':disabled,.disabled')) ? getNextF($next) : $next;
  };
  var getPrevF = function($input) {
    const navData = $input.data('inputNav') || {};
    let $prev = $();
    if(navData.$prev) $prev = navData.$prev;
    if(!$prev.length) {
      const $container = getContainerF($input);
      if(navData.prev) {
        if(!$container.length) $container = $(document);
        $prev = $(navData.prev, $container);
      } else if($container.length) {
        const $inputs = $container.find(inputItemSelector);
        const i = $inputs.index($input);
        if(i >= 1) {
          $prev = $inputs.eq(i-1);
        }
      }
      if($prev.length) {
        updateConnectionF($prev, $input);
      }
    }
    return ($prev.length && $prev.is(':disabled,.disabled')) ? getPrevF($prev) : $prev;
  };
  var getTopF = function($input) {
    const navData = $input.data('inputNav') || {};
    let $top = $();
    if(navData.$top) $top = navData.$top;
    if(!$top.length) {
      const $container = getContainerF($input);
      if($container.length && $input.data('inputNavCol')) {
        const $inputs = $container.find(inputItemSelector).filter('[data-input-nav-col="'+$input.data('inputNavCol')+'"]');
        const i = $inputs.index($input);
        if(i >= 1) {
          $top = $inputs.eq(i-1);
        }
        if($top.length) {
          updateTopBottomF($top, $input);
        }
      }
    }
    return ($top.length && $top.is(':disabled,.disabled')) ? getTopF($top) : $top;
  }
  var getBottomF = function($input) {
    const navData = $input.data('inputNav') || {};
    let $bottom = $();
    if(navData.$bottom) $bottom = navData.$bottom;
    if(!$bottom.length) {
      const $container = getContainerF($input);
      if($container.length && $input.data('inputNavCol')) {
        const $inputs = $container.find(inputItemSelector).filter('[data-input-nav-col="'+$input.data('inputNavCol')+'"]');
        const i = $inputs.index($input);
        if(i >= 0) {
          $bottom = $inputs.eq(i+1);
        }
        if($bottom.length) {
          updateTopBottomF($input, $bottom);
        }
      }
    }
    return ($bottom.length && $bottom.is(':disabled,.disabled')) ? getBottomF($bottom) : $bottom;
  }
  $('body').off('.formInputNav').on('keydown.formInputNav', '[data-input-nav]', function(e){
    var $input = $(this);
    if($input.is('.select2-hidden-accessible')) return true;
    const alternate = e.shiftKey;
    // 9  : tab
    // 13 : return
    // 38 : up
    // 40 : down
    if($input.is('textarea') && ([38, 40].indexOf(e.which) >= 0 || (e.which == 13 && alternate))) {
      return true;
    }
    let navData = $input.data('inputNav');
    if(!$.isPlainObject(navData)) navData = {};
    let direction = 0;
    // 0 : none
    // 1 : up
    // 2 : right
    // 3 : down
    // 4 : left
    switch(e.which) {
      case 9:
        direction = alternate ? 4 : 2;
        break;
      case 13:
        direction = 2;
        break;
      case 38:
        direction = 1;
        break;
      case 40:
        direction = 3;
    }
    if(direction <= 0) return true;
    setTimeout(function(){
      switch(direction) {
        case 1:
          triggerFocusF(getTopF($input));
          break;
        case 2:
          triggerFocusF(getNextF($input));
          break;
        case 3:
          triggerFocusF(getBottomF($input));
          break;
        case 4:
          triggerFocusF(getPrevF($input));
      }
    }, 0);
    return false;
  }).on('select2:select.formInputNav select2:close.formInputNav', '[data-input-nav]', function() {
    var $input = $(this);
    setTimeout(function(){
      if(!$('.select2-container--open').length && $input.find('option:selected').length && ($input.is(':focus') || $(':focus').is('.select2-selection'))) {
        triggerFocusF(getNextF($input));
      }
    }, 0);
  });
});
