import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  const $cardsContainer = $('.dashboard-cards')
  if($cardsContainer.length) {
    const $cards = $cardsContainer.find('.card')
    $cards.each(function() {
      const $card = $(this)
      const isHidden = $card.hasClass('hidden')
      const $refreshButton = $card.find('.widget-refresh-button')
      const $showButton = $card.find('.widget-show-button')
      const $hideButton = $card.find('.widget-hide-button')
      const $content = $card.find('.widget-content[data-url]')
      $content.addClass('collapse').collapse({toggle: false})
      $showButton.click(function(e){
        e.preventDefault()
        $card.removeClass('hidden')
        $refreshButton.click()
      })
      $hideButton.click(function(e){
        e.preventDefault()
        $card.addClass('hidden')
        $content.collapse('hide')
      })
      $refreshButton.click(function(e){
        e.preventDefault()
        if($content.hasClass('show')) {
          $content.one('hidden.bs.collapse', function(){
            $refreshButton.click()
          }).collapse('hide')
          return;
        }
        $.ajax($content.data('url'), {
          success: function(data) {
            $content.html(data)
            $('.clickable-row', $content).clickableRow()
            $content.collapse('show')
          },
          error: function(xhr, status, error) {
            $.flash.create('Error ' + status +  ' ' + error, 'danger', { timeout: 5000 })
          },
        })
      })
      if(!isHidden) {
        $refreshButton.click()
      }
    })
  }
})
