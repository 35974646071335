import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  var $dropdownEl = $('#notification_dropdown:not(.initiated)');
  if($dropdownEl.length) {
    $dropdownEl.addClass('initiated')
    var $listEl = $dropdownEl.find('#user_notifications_container');
    var $emptyEl = $dropdownEl.find('#user_notification_empty');
    var $countEl = $dropdownEl.find('#notification_dropdown_unread_count');
    var $linkEl = $dropdownEl.find('#notification_dropdown_link');
    var notificationMessage = $dropdownEl.data('notificationMessage');
    var notificationTitle = $dropdownEl.data('notificationTitle');
    $dropdownEl.on('refresh', function(){
      const url = $dropdownEl.data('refreshUrl');
      $.get(url, function(data){
        $listEl.children(':not(.view-all)').remove();
        if(data.count == 0) {
          $listEl.addClass('d-none');
          $emptyEl.removeClass('d-none');
        } else {
          $listEl.removeClass('d-none');
          $emptyEl.addClass('d-none');
          $listEl.prepend(data.html);
          $('.clickable-row', $listEl).clickableRow();

          data.unread_count = Number(data.unread_count);
          if(data.unread_count > 0) {
            $linkEl.addClass('active');

            const oldUnread = Number($countEl.text());
            if(oldUnread < data.unread_count) {
              const timestamp = $listEl.find('.timestamp:first').text();
              const $notificationEl = $.toast.create(notificationMessage.replace('COUNT', data.unread_count - oldUnread), {title: notificationTitle, autohide: true, timestamp: timestamp});
              $notificationEl.find('.toast-body').click(function(){
                $.toast.remove($notificationEl, function(){
                  $linkEl.click();
                });
              }).css('cursor', 'pointer');
            }
          } else {
            $linkEl.removeClass('active');
          }
        }
        $countEl.text(data.unread_count);
      }, 'json');
    }).on('shown.bs.dropdown', function(){
      if($linkEl.hasClass('active'))
        $.post($linkEl.data('readUrl'), {_method: 'PATCH'}, null, 'json');
    }).on('hidden.bs.dropdown', function(){
      if($linkEl.hasClass('active')) {
        $dropdownEl.trigger('refresh');
      }
    }).trigger('refresh');
  }
});
