import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  $('#price_detail_modal').on({
    'show.bs.modal': function(e){
      const $link = $(e.relatedTarget)
      const $modal = $(e.target)
      const $modalBody = $modal.find('.modal-body')
      const $modalTitle = $modal.find('.modal-header .title')

      $modalBody.children().detach()
      const $modalContent = $link.data('$modalContent')
      if(!$modalContent) {
        $modalTitle.text($link.data('title'))

        $('<div class="spinner-border spinner-border-lg my-5">')
          .html('<span class="sr-only">Loading...</span>')
          .appendTo($modalBody)
        $modalBody.addClass('loading text-center')
        const xhr = $.ajax($link.attr('href'), {
          dataType: 'html',
          success: function(data) {
            const $content = $(data).appendTo($modalBody)
            $link.data('$modalContent', $content)
          },
          error: function(xhr, status, error) {
            $.flash.create('Error ' + status +  ' ' + error, 'danger', { timeout: 5000 })
            console.debug(arguments)
            $modal.modal('hide')
          },
          complete: function() {
            $modalBody
              .removeClass('loading text-center')
              .find('.spinner-border').remove()
            $modal.data('xhr', null)
          }
        })
        $modal.data('xhr', xhr)
      } else {
        $modalBody.append($modalContent)
      }
    },
    'hide.bs.modal': function(e){
      const $modal = $(e.target)
      const $modalBody = $modal.find('.modal-body')
      if($modalBody.hasClass('loading') && $modal.data('xhr')) {
        $modal.data('xhr').abort()
      }
    }
  })
})
