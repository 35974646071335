import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  const $totalUnpaid = $('#customer_total_unpaid_sales', '#customer_unpaid_sales')
  const $headerTotalUnpaid = $('#customer_total_unpaid_sales_header')
  if($totalUnpaid.length && Number($totalUnpaid.text()) !== 0) {
    $headerTotalUnpaid.find('a').text($totalUnpaid.text())
  } else {
    $headerTotalUnpaid.closest('.col').hide()
  }
})
