import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  const $financeSaleBody = $('body.finance_sales-controller')
  $('#date_duration', $financeSaleBody).on('change', function(){
    const value = $(this).val()
    const $form = $(this).closest('form')
    $('[data-date-selector]', $form)
      .hide()
        .find(':input').prop('disabled', true).end()
      .filter('[data-date-selector="'+value+'"]')
        .show()
          .find(':input').prop('disabled', false)
  }).triggerHandler('change')
})
