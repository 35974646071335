import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  // $('[data-ajax-replace]').on('ajax:success', function(e){
  //   const [data, status, xhr] = e.detail;
  //   const target = $(this).data('ajaxReplace');
  //   const $target = $(target).removeClass('ajax-new-content');

  //   $target.replaceWith(data);
  //   $target.addClass('ajax-new-content');
  // });

  $('[data-ajax-remove]').on('ajax:success', function(e){
    const [data, status, xhr] = e.detail;
    const target = $(this).data('ajaxRemove');
    var $target = (target === 'self') ? $(this) : $(target);
    var oncomplete = function() {
      $target.remove();
    };
    var duration = $(this).data('ajaxAnimateDuration') || 300;
    var $animateTarget = $target;
    if($animateTarget.is('tr')) {
      const $cell = $animateTarget.children('td, th');
      $animateTarget = $cell
        .wrapInner('<div>')
        .children();
      $animateTarget = $animateTarget.add($cell);
    }
    switch($(this).data('ajaxAnimate')) {
      case 'slide':
        $animateTarget.slideUp(duration, oncomplete);
        break;
      case 'fade':
        $animateTarget.fadeOut(duration, oncomplete);
        break;
      case 'all':
        $animateTarget.hide(duration, oncomplete);
        break;
      default:
        oncomplete();
    }
  });

  $('[data-remote]:not([data-disable-remote-handler])').on({
    'ajax:error': function(e){
      const [data, status, xhr] = e.detail;
      try {
        const json_data = (typeof data === 'string') ? JSON.parse(data) : data;
        $.flash.remove('.ajax-flash-alert');
        for(const key in json_data) {
          var name = (key === 'base') ? '' : key + ' ';
          for(const message of json_data[key]){
            $.flash.create(name + message, 'danger', {class: 'ajax-flash-alert'});
          }
        }
      } catch (e) {
        console.debug('Unknown error', e, data, status, xhr);
      }
    },
    'ajax:success': function(e){
      const [data, status, xhr] = e.detail;
      if(data && data.length) {
        try {
          const json_data = (typeof data === 'string') ? JSON.parse(data) : data;
          if(json_data.updates) {
            $.each(json_data.updates, function(selector, content){
              $(selector).text(content).highlight();
            });
          }
        } catch (e) {
          console.debug('Unknown data', e, data, status, xhr);
        }
      }
    }
  });
});
