import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  $('.inline-editable:not(.inline-editable-active)').each(function() {
    const $editable = $(this);
    const url = $editable.data('inlineEditableUrl');
    const method = $editable.data('inlineEditableMethod');
    const name = $editable.data('inlineEditableName');
    if(!url || !method || !name) return;
    $editable.on('click', function(){
      const originalValue = $editable.find('.text').text();
      $editable.hide();
      const $input = $('<input type="text" name="'+name+'">').insertAfter($editable);
      $input
        .css({width: Math.max($editable.width(), 200), height: $editable.height, padding: 0, margin: 0})
        .val(originalValue)
        .focus()
        .on('blur.inlineEditable keydown.inlineEditable', function(e){
          const value = $input.val();
          const closeInputF = function() {
            $input.off('.inlineEditable').remove();
            $editable.show();
          }
          if(e.type === 'keydown') {
            if(e.key === 'Enter') {
              e.preventDefault();
            } else if(e.key === 'Esc' || e.key === 'Escape') {
              closeInputF();
              return;
            } else {
              return;
            }
          }
          if(originalValue === value) {
            closeInputF();
            return;
          }
          const data = {
            _method: method
          };
          data[name] = value;
          $input.prop('readonly', true)
          $.flash.remove('#inline-editable-error')
          $.post({
            url: url,
            data: data,
            dataType: 'json',
            complete: function(){
              $input.prop('readonly', false);
            },
            error: function(response, status, error){
              let message = error;
              if(response && response.responseJSON) {
                message = $.map(response.responseJSON, function(value, key){ return value[0] })[0]
              }
              $.flash.create(message, 'danger', { id: 'inline-editable-error', class: 'flash-fixed' }, { timeout: 5000 })
              setTimeout(function(){
                $input.focus();
              }, 10);
            },
            success: function(response){
              closeInputF();
              if(response.updates) {
                $editable.find('.text').text(response.updates).highlight();
              }
            }
          });
        })
    })
    $editable
      .addClass('inline-editable-active')
      .text($.trim($editable.text()))
      .wrapInner('<span class="text">')
      .append('<i class="bi bi-pencil-square d-print-none pl-2"></i>');
  });
  // $('[data-ajax-remove]').on('ajax:success', function(e){
  //   const [data, status, xhr] = e.detail;
  //   const target = $(this).data('ajaxRemove');
  //   var $target = (target === 'self') ? $(this) : $(target);
  //   var oncomplete = function() {
  //     $target.remove();
  //   };
  //   var duration = $(this).data('ajaxAnimateDuration') || 300;
  //   var $animateTarget = $target;
  //   if($animateTarget.is('tr')) {
  //     const $cell = $animateTarget.children('td, th');
  //     $animateTarget = $cell
  //       .wrapInner('<div>')
  //       .children();
  //     $animateTarget = $animateTarget.add($cell);
  //   }
  //   switch($(this).data('ajaxAnimate')) {
  //     case 'slide':
  //       $animateTarget.slideUp(duration, oncomplete);
  //       break;
  //     case 'fade':
  //       $animateTarget.fadeOut(duration, oncomplete);
  //       break;
  //     case 'all':
  //       $animateTarget.hide(duration, oncomplete);
  //       break;
  //     default:
  //       oncomplete();
  //   }
  // });

  // $('[data-remote]').on({
  //   'ajax:error': function(e){
  //     const [data, status, xhr] = e.detail;
  //     try {
  //       const json_data = (typeof data === 'string') ? JSON.parse(data) : data;
  //       $.flash.remove('.ajax-flash-alert');
  //       for(const key in json_data) {
  //         var name = (key === 'base') ? '' : key + ' ';
  //         for(const message of json_data[key]){
  //           $.flash.create(name + message, 'danger', {class: 'ajax-flash-alert'});
  //         }
  //       }
  //     } catch (e) {
  //       console.debug('Unknown error', e, data, status, xhr);
  //     }
  //   },
  //   'ajax:success': function(e){
  //     const [data, status, xhr] = e.detail;
  //     try {
  //       const json_data = (typeof data === 'string') ? JSON.parse(data) : data;
  //       if(json_data.updates) {
  //         $.each(json_data.updates, function(selector, content){
  //           $(selector).text(content).highlight();
  //         });
  //       }
  //     } catch (e) {
  //       console.debug('Unknown data', e, data, status, xhr);
  //     }
  //   }
  // });
});
