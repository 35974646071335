import $ from 'jquery'
import AutoNumeric from 'autonumeric';
$.fn.select2.defaults.set( "theme", "bootstrap" );
$.fn.select2.defaults.set( "minimum-results-for-search", 10 );
$.fn.select2.defaults.set( "width", '100%' );

$(document).on("turbolinks:render turbolinks:load", function() {
  $('.select2-input').select2();
  setTimeout(function() {
    var $select_focus = $('.select2-hidden-accessible[autofocus]:first');
    $select_focus.select2('focus');
    // if(!$.trim($select_focus.val()).length)
    //   $select_focus.select2('open');
  }, 0);
});

// fix select2 search field focus, ref: https://forums.select2.org/t/search-being-unfocused/1203/14
$(document).on('select2:open', () => {
  setTimeout(function(){
    document.querySelector('.select2-container--open .select2-search__field').focus();
  });
});

// allows typing directly on focus
$(document).on('keypress', '.select2-focus-typing', function(e) {
  $(e.target).closest('.select2-container').prevAll('.select2-hidden-accessible').select2('open');
  $('.select2-container--open .select2-search__field').val(e.key);
});

document.addEventListener("turbolinks:before-cache", function() {
  $('.select2-hidden-accessible').select2('destroy');
});

$(document).on("turbolinks:load", function() {
  $('.selectable-row').selectableRow();
  $('.clickable-row').clickableRow();
  $('.select-all-button').selectAllButton();
  $('[data-toggle="tooltip"]').tooltip();
  $('.change-submit-input').change(function(){
    $(this).closest('form').submit();
  });
  $('body').on('cocoon:after-insert', function(e, insertedItem){
    $(insertedItem).find('[autofocus]:first').focus();
    $(insertedItem).initMoneyInput();
  });
  // fix bootstrap collapse animation on tr
  $('tr.collapse').on({
    'show.bs.collapse': function(){
      $(this).hide().fadeIn();
    },
    'hide.bs.collapse': function(){
      $(this).show().fadeOut();
    }
  });
  $(document).initMoneyInput();
});

$.flash = {
  containerID: '#flash',
  create: function(message, type, attrs, options) {
    const $template = $('#flash_alert_template');
    if(!$template.length) return;

    if(typeof type === 'undefined' || !type) type = 'info';
    if(typeof attrs === 'undefined' || !attrs) attrs = {};
    if(typeof options === 'undefined' || !options) options = {};
    if(!attrs.id) attrs.id = 'flash-'+(new Date).getTime()+'-'+Math.floor(Math.random()*1000);
    let append_class = 'alert-' + type;
    if(attrs.class) {
      append_class += ' ' + attrs.class;
      delete(attrs.class);
    }

    $template.clone().appendTo(this.containerID)
      .prepend(message)
      .attr(attrs)
      .addClass(append_class)
      .removeClass('d-none');
    if(options.timeout) {
      setTimeout(function(){$.flash.remove('#'+attrs.id)}, options.timeout);
    }
  },
  remove: function(selector) {
    let $flash = $(this.containerID).find('.flash-alert:not(#flash_alert_template)');
    if(typeof selector !== 'undefined') {
      $flash = $flash.filter(selector);
    }
    $flash.alert('close');
  }
}

$.toast = {
  containerID: '#toast_container',
  create: function(body, options) {
    const $template = $('#toast_template');
    if(!$template.length) return;
    options = Object.assign({
      title: '',
      body: body,
      timestamp: null,
      autohide: false,
      delay: 5000
    }, options);

    const id = 'toast-'+(new Date).getTime()+'-'+Math.floor(Math.random()*1000);
    const $el = $template.clone().appendTo(this.containerID).attr({id: id});
    $.toast.update($el, options);
    $el.on('hidden.bs.toast', function(){ $(this).remove(); });
    $el.toast(options).toast('show');
    return $el;
  },
  update: function(el, options) {
    const $el = $(el);
    options ||= {};
    if(options.title) {
      $el.find('.toast-title').text(options.title);
    }
    if(options.timestamp) {
      $el.find('.toast-timestamp').text(options.timestamp);
    } else {
      $el.find('.toast-timestamp').remove();
    }
    if(options.body) {
      $el.find('.toast-body').html(options.body);
    }
  },
  remove: function(el, onRemove) {
    const $el = $(el);
    $el.toast('hide');
    if($.isFunction(onRemove)) {
      $el.on('hidden.bs.toast', onRemove);
    }
  }
}

$.money = {
  format: function(amount, format = {}) {
    format = $.extend({symbol: 'Rp', thousands_separator: '.', decimal_mark: ','}, format);
    amount = Number(amount);
    if(isNaN(amount)) {
      amount = Number();
    }

    let formatted_amount = amount.toFixed(2);
    if(format.decimal_mark) {
      formatted_amount = formatted_amount.replace('.', format.decimal_mark);
    }
    if(format.thousands_separator) {
      formatted_amount = formatted_amount.replace(/\B(?=(\d{3})+(?!\d))/g, format.thousands_separator);
    }
    if(format.symbol) {
      formatted_amount = format.symbol + formatted_amount;
    }

    return formatted_amount;
  },
  parse: function(str, format = {}) {
    format = $.extend({symbol: 'Rp', thousands_separator: '.', decimal_mark: ','}, format);

    let formatted_amount = str;
    if(format.symbol) {
      formatted_amount = formatted_amount.replace(format.symbol, '');
    }
    if(format.thousands_separator) {
      formatted_amount = formatted_amount.replaceAll(format.thousands_separator, '');
    }
    if(format.decimal_mark) {
      formatted_amount = formatted_amount.replace(format.decimal_mark, '.');
    }

    const amount = Number(formatted_amount);
    return amount;
  }
}

$.fn.selectableRow = function() {
  $(this).each(function(){
    const $row = $(this);
    var $cb = $($row.data('selectableRowCheckbox') || 'input[type=checkbox]', $row);
    const $clickable = $($row.data('selectableRowClickable') || '.selectable', $row);
    const $input = $($row.data('selectableRowInput') || 'input:not(:hidden)', $row).not($cb);

    $cb.off('click.selectableRow').on('click.selectableRow', function() {
      const $this = $(this);
      if($this.prop('checked')) {
        $this.closest('tr').addClass('table-active');
      } else {
        $this.closest('tr').removeClass('table-active');
      }
    }).each(function(){$(this).triggerHandler('click.selectableRow')});

    $clickable.off('click.selectableRow').on('click.selectableRow', function() {
      $cb.click();
    }).css('cursor', 'pointer');

    $input.off('keyup.selectableRow').on('keyup.selectableRow', function() {
      const $this = $(this);
      const prev_value = $(this).data('prevValue');
      if(this.value == prev_value) return;
      $cb.filter(':not(:checked)').click();
      $this.data('prevValue', this.value);
    }).each(function(){
      if(!$(this).data('prevValue')) $(this).data('prevValue', this.value);
    });
  });
};

$.fn.clickableRow = function() {
  $(this).filter('[data-url][data-url!=""]').addClass('clickable-active').on('click', function(e) {
    if(!(e.shiftKey || e.metaKey) && getSelection().toString().length) return;
    const url = $(this).data('url');
    const $clickable = $(e.target).closest('a,:button,:input,[data-url]');
    if(url && $clickable.is(this)) {
      if(e.shiftKey || e.metaKey) {
        window.open(url, '_blank').focus();
      } else {
        Turbolinks.visit(url);
      }
    }
  });
};

$.fn.selectAllButton = function() {
  $(this).on('click', function(e) {
    const parent = $(this).data('selectAllParent');
    const $parent = parent ? $(this).closest(parent) : $(document);
    const items = $(this).data('selectAllItems') || ':input:checkbox';
    const $items = $(items, $parent);

    if(!$items.filter(':not(:checked)').click().length) {
      $items.filter(':checked').click();
    }
  }).css('cursor', 'pointer');
};

$.fn.highlight = function() {
  var $input = $(this);
  $input.addClass('highlight-flash');
  setTimeout(function(){
    $input.removeClass('highlight-flash');
  },1000);
};

$.fn.initMoneyInput = function(container) {
  const moneyFormat = $('body').data('moneyFormat');
  $('.money-input', container).each(function(){
    const $input = $(this);
    if($input.data('autoNumeric')) $input.data('autoNumeric').remove();
    const elMoneyFormat = $input.data('moneyFormat') || moneyFormat;
    let minimumValue = $input.data('min') || '-10000000000000';
    let maximumValue = $input.data('max') || '10000000000000';
    try {
      $input.data('autoNumeric', new AutoNumeric(this, {
        decimalCharacter: elMoneyFormat.decimal_mark,
        digitGroupSeparator: elMoneyFormat.thousands_separator,
        currencySymbol: elMoneyFormat.symbol || '',
        decimalPlaces: 2,
        minimumValue: minimumValue,
        maximumValue: maximumValue,
        selectOnFocus: false,
        watchExternalChanges: true
      }));
    } catch(e) {
      if($input.val() > maximumValue) $input.val(maximumValue);
      else if($input.val() < minimumValue) $input.val(minimumValue);
      $input.data('autoNumeric', new AutoNumeric(this, {
        decimalCharacter: elMoneyFormat.decimal_mark,
        digitGroupSeparator: elMoneyFormat.thousands_separator,
        currencySymbol: elMoneyFormat.symbol || '',
        decimalPlaces: 2,
        minimumValue: minimumValue,
        maximumValue: maximumValue,
        selectOnFocus: false,
        watchExternalChanges: true
      }));
    }
  });
}

$.fn.numVal = function() {
  let $input = $(this);
  if(arguments.length > 0) {
    let value = arguments[0];
    if(!arguments[1] && (value === Infinity || isNaN(value) || value === null)) value = '';
    $input.each(function(){
      const autoNumeric = $(this).data('autoNumeric')
      if(autoNumeric) {
        autoNumeric.set(value)
      } else {
        $(this).val(value)
      }
    });
    return $input
  }

  $input = $input.first();
  const autoNumeric = $(this).data('autoNumeric')
  return (autoNumeric) ? autoNumeric.getNumber() : Number($input.val())
}

global.jQuery = $;
