import $ from 'jquery'
import 'select2'

$(document).on("turbolinks:render turbolinks:load", function() {
  var inputDelay = 800;

  var resultTemplate = function(state) {
    if(typeof state.id === 'undefined') return state.text;
    var $div = $('<div><div class="name">' + state.text + '</div></div>');
    if(state.mfr_code) {
      $div.prepend('<div class="mfr-code">'+state.mfr_code+'</div>');
    }
    if(state.code) {
      if(state.type === 'Accessory') {
        $div.prepend('<div class="code-acc">'+state.code+'</div>');
      } else {
        $div.prepend('<div class="code">'+state.code+'</div>');
      }
    }
    if(state.color) {
      $div.append('<div class="color">'+state.color+'</div>');
    }
    if(state.perimeter) {
      $div.append('<div class="perimeter">'+state.perimeter+'</div>');
    }
    if(state.dimension) {
      $div.append('<div class="dimension">'+state.dimension+'</div>');
    }
    if(typeof state.locations !== 'undefined') {
      var $locations = $('<div class="locations">').appendTo($div);
      $.each(state.locations, function(code, data) {
        const $location = $('<div class="location">').appendTo($locations);
        $location.append('<span class="location-code">'+code+'</span> : ');
        $location.append('<span class="location-stock">'+data.stock+'</span> ');
        $location.addClass((data.is_active) ? 'text-primary' : 'text-secondary');
        if(state.unit_name) {
          $location.append('<span class="unit_name">'+state.unit_name+'</span>');
        }
      });
    } else if(typeof state.quantity !== 'undefined') {
      const $quantity = $('<div class="quantity">'+state.quantity+'</div>').appendTo($div);
      if(state.unit_name) {
        $quantity.append(' <span class="unit_name">'+state.unit_name+'</span>');
      }
    }
    return $div.children();
  };
  var selectTemplate = function(state) {
    if(typeof state.id === 'undefined') return state.text;
    var $div = $('<div class="product-search-select"><div class="name">' + state.text + '</div></div>');
    if(state.mfr_code) {
      $div.prepend('<div class="mfr-code">'+state.mfr_code+'</div>');
    }
    if(state.code) {
      $div.prepend('<div class="code">'+state.code+'</div>');
    }
    if(state.color) {
      $div.append('<div class="color">'+state.color+'</div>');
    }
    if(state.perimeter) {
      $div.append('<div class="perimeter">'+state.perimeter+'</div>');
    }
    if(state.dimension) {
      $div.append('<div class="dimension">'+state.dimension+'</div>');
    }
    return $div;
  };

  var $productSearch = $(':input[data-role="product_search"]').select2({
    closeOnSelect: true,
    maximumInputLength: 100,
    minimumInputLength: 2,
    templateSelection: selectTemplate,
    templateResult: resultTemplate,
    dropdownCssClass: 'product-search-dropdown',
    containerCssClass: 'product-search-container select2-focus-typing',
    ajax: {
      delay: inputDelay,
      url: function(){
        const $selector = $($(this).data('urlSelector'));
        return $(this).data('urls')[$selector.val()];
      },
      dataType: 'json',
      data: function(params) {
        let data = {
          q: params.term
        };
        if($(this).data('location')) {
          const $location = $($(this).data('location'));
          if($location.length) data.l_id = $location.val();
        }
        if($(this).data('specialDiscount')) {
          const $specialDiscount = $($(this).data('specialDiscount'));
          if($specialDiscount.length) data.s_d_id = $specialDiscount.val();
        }
        return data;
      },
      error: function(xhr, status) {
        if(xhr.responseJSON && xhr.responseJSON.errors) {
          $.each(xhr.responseJSON.errors, function(i, error) {
            $.flash.create(error, 'danger', {id: 'error_product_search'});
          });
        } else if(status !== 'abort') {
          $.flash.create('Unknown error!', 'danger', {id: 'error_product_search'});
        }
      },
      beforeSend: function() {
        $.flash.remove('#error_product_search');
      }
    }
  });
});

document.addEventListener("turbolinks:load", function() {
  var colorTemplate = function(state){
    if(typeof state.id === 'undefined') return state.text;
    var $span = $('<span><span class="text">' + state.text + '</span></span>');
    if(typeof state.quantity !== 'undefined') {
      $span.append(' <span class="quantity">x '+state.quantity+'</span>');
    }
    return $span;
  };

  $(':input[data-role=product_coating_color]').on('select2:select.productSearch', function(e) {
    if(!e.params) return;
    var data = e.params.data;
    if(!data) data = {};

    if($(this).data('basePrice')) {
      const $base_price = $($(this).data('basePrice'));
      if(typeof data.base_price !== 'undefined') {
        $base_price.val(data.base_price).prop('disabled', false);
      } else {
        $base_price.val('0').prop('disabled', true);
      }
    }
  });

  var $product_search = $(':input[data-role="product_search"]');
  $product_search.on('select2:select.productSearch select2:unselect.productSearch', function(e){
    if(!e.params) return;

    var data = e.params.data;
    if(!data) data = {};

    if($(this).data('color')) {
      const $color = $($(this).data('color'));
      if(typeof data.price !== 'undefined') {
        $color.val(data.color);
      } else {
        $color.val('');
      }
    }
    if($(this).data('quantity')) {
      const $quantity = $($(this).data('quantity'));
      if(typeof data.quantity !== 'undefined' || (data.id && $(this).data('ignoreStock'))) {
        $quantity.prop('disabled', false);
      } else {
        $quantity.val('0').prop('disabled', true);
      }
    }
    let showQuantityAppend = false;
    if($(this).data('stock')) {
      const $stock = $($(this).data('stock'));
      if(typeof data.quantity !== 'undefined') {
        $stock.text(data.quantity);
        showQuantityAppend = true;
      } else {
        $stock.text('');
      }
    }
    if($(this).data('unitName')) {
      const $unit_name = $($(this).data('unitName'));
      if(typeof data.unit_name !== 'undefined') {
        $unit_name.text(data.unit_name);
        showQuantityAppend = true;
      } else {
        $unit_name.text('');
      }
    }
    if($(this).data('quantityAppend')) {
      const $quantity_append = $($(this).data('quantityAppend'));
      if(showQuantityAppend) {
        $quantity_append.show();
      } else {
        $quantity_append.hide();
      }
    }
    if($(this).data('price')) {
      const $price = $($(this).data('price'));
      if(typeof data.price !== 'undefined') {
        $price.val(data.price).data('originalValue', data.price).prop('disabled', false);
      } else {
        $price.val('0').data('originalValue', '0').prop('disabled', true);
      }
    }
    if($(this).data('basePrice')) {
      const $base_price = $($(this).data('basePrice'));
      if(typeof data.base_price !== 'undefined') {
        $base_price.val(data.base_price).prop('disabled', false);
      } else {
        $base_price.val('0').prop('disabled', true);
      }
    }
    if($(this).data('pricelist')) {
      const $pricelist = $($(this).data('pricelist'));
      if(typeof data.pricelist !== 'undefined') {
        $pricelist.text(data.pricelist).show();
      } else {
        $pricelist.hide().text('');
      }
    }
    if($(this).data('discount')) {
      const $discount = $($(this).data('discount'));
      if(typeof data.discount !== 'undefined') {
        $discount.closest('.discount-container').removeClass('d-none').addClass('d-flex');
        $discount.val(data.discount).data('originalValue', data.discount).prop('disabled', false);
      } else {
        $discount.val('').data('originalValue', '').prop('disabled', true);
        $discount.closest('.discount-container').removeClass('d-flex').addClass('d-none');
      }
    }
    if($(this).data('coatingColor')) {
      const $coating_color = $($(this).data('coatingColor'));
      $coating_color.html('').select2('data', null);
      if(data.coating_colors) {
        $coating_color.select2({
          data: data.coating_colors,
          templateSelection: colorTemplate,
          templateResult: colorTemplate
        })
        .prop('disabled', false)
        .triggerHandler({
          type: 'select2:select.productSearch',
          params: {
            data: data.coating_colors[0]
          }
        });
      } else {
        $coating_color.val('').prop('disabled', true).triggerHandler({
          type: 'select2:select.productSearch',
          params: {
            data: null
          }
        });
      }
    }
  }).on('reset', function(){
    if($(this).hasClass("select2-hidden-accessible")){
      $(this).html('').select2('data', null);
      $(this).trigger({type: 'select2:unselect', params: {}});
    }
  }).triggerHandler('reset');;

  $($product_search.data('urlSelector')).on('select2:select', function() {
    $product_search.trigger('reset');
  });

  $('.product-search-selector').click(function() {
    $(':input[data-role="product_search"]').trigger('reset');
    $('.product-types-fields').addClass('d-none')
      .filter('[data-product-type="'+$(this).data('id')+'"]').removeClass('d-none');
  }).filter(':checked').triggerHandler('click');
});

document.addEventListener("turbolinks:before-cache", function() {
  $('.add-product-fields :input').trigger('reset');
});
