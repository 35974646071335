import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  const customerSelectTemplate = function(state) {
    const $span = $('<span>' + state.text + '</span>');
    if(!isNaN(state.id) && parseInt(state.id) > 0) {
      $span.prepend('<i class="bi bi-person-fill pr-2"></i>');
    }
    return $span;
  };
  const $customer_select = $('#sale_filter_customer_select');
  $customer_select.select2({
    tags: true,
    minimumResultsForSearch: 0,
    templateSelection: customerSelectTemplate,
    templateResult: customerSelectTemplate,
    containerCssClass: 'select2-focus-typing',
    allowClear: true
  })
})
