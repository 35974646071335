import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  $('.btn-print').click(function(e) {
    e.preventDefault();
    $('.print-timestamp').text((new Date()).toLocaleString('id-ID'));
    const $printHidden = $($(this).data('printHidden'));
    $printHidden.addClass('d-print-none');
    window.print();
    $printHidden.removeClass('d-print-none');
  });
});
