import $ from 'jquery'

$(document).on("turbolinks:load", function() {
  var calculateDiscountF = function(basePrice, sellPrice, profitPercent) {
    const discount =  200 - (((profitPercent*basePrice/100)+basePrice+sellPrice)*100/sellPrice);
    return parseFloat(discount.toFixed(2)).toString().replace('.', ',').replace(/(,[^0]+)0+/, '$1');
  };
  var setDiscountF = function($input, basePrice, sellPrice, profitPercent) {
    const discount = calculateDiscountF(basePrice, sellPrice, profitPercent);
    if($input.val() !== discount) {
      $input.val(calculateDiscountF(basePrice, sellPrice, profitPercent));
      $input.highlight();
    }
  }

  var $basePrice = $('#aluminium_price_base_price');
  var $sellPrice = $('#aluminium_price_sell_price');
  $basePrice.add($sellPrice).on('blur', function() {
    const $input = $(this);
    if($input.val() !== $input.data('originalValue')) {
      const basePrice = $.money.parse($basePrice.val());
      const sellPrice = $.money.parse($sellPrice.val());
      setDiscountF($('#aluminium_price_discount'), basePrice, sellPrice, 10);
      setDiscountF($('#aluminium_price_special_discount1'), basePrice, sellPrice, 9);
      setDiscountF($('#aluminium_price_special_discount2'), basePrice, sellPrice, 8);
      setDiscountF($('#aluminium_price_special_discount3'), basePrice, sellPrice, 7);
      setDiscountF($('#aluminium_price_special_discount4'), basePrice, sellPrice, 6);
      setDiscountF($('#aluminium_price_special_discount5'), basePrice, sellPrice, 5);
      setDiscountF($('#aluminium_price_favorite_discount'), basePrice, sellPrice, 8);
      $input.data('originalValue', $input.val());
    }
  });

});
